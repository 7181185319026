import { formatCatalogBFFListingGood } from "@magnit/unit-catalog/src/utils/converter";
import type { ICatalogBFFListingGood } from "@magnit/unit-catalog/src/types";
import type {
  ITransformedProductsResponse,
  CatalogBFFGoodsSearchV2Response,
  ICatalogBFFGoodsSearchV2Params,
  ICatalogBFFDetailedGood,
  IRequestGoodsFilters,
  IGoodsFilters,
} from "~/typings/api/";
import { urls } from "~/api/config";
import { API_CATALOG_TYPE, API_SHOP_TYPE } from "~/constants/api";

export default () => {
  const storesStore = useStoresStore();
  const { storeShopCode } = storeToRefs(storesStore);

  const requestProducts = (
    params:
      | Ref<ICatalogBFFGoodsSearchV2Params>
      | ICatalogBFFGoodsSearchV2Params,
    key?: string,
    immediate = true,
    watch = true,
  ) => {
    const transform = (
      data: CatalogBFFGoodsSearchV2Response,
      currentCategoryId?: number | string,
    ): ITransformedProductsResponse => {
      const appendUrl = (
        product: ICatalogBFFListingGood,
        categoryId?: number | string,
      ) => {
        let url = `${Routes.CatalogProduct}/${product.id}-${product.seoCode}?shopCode=${storeShopCode.value}&shopType=${API_SHOP_TYPE}`;
        if (categoryId) url += `&category=${categoryId}`;
        return { ...product, url };
      };

      return {
        goods:
          data.items
            ?.map((product) => appendUrl(product, currentCategoryId))
            .map((product) => formatCatalogBFFListingGood(product)) || [],
        pagination: {
          total: data.pagination.totalCount,
          pageSize: data.pagination.limit,
          page:
            Math.ceil(
              (data.pagination.offset || 0) / (data.pagination.limit || 1),
            ) + 1,
        },
      };
    };

    const body = computed(() => {
      return {
        ...toValue(params),
        includeAdultGoods: true,
        storeCode: String(storeShopCode.value),
        storeType: String(API_SHOP_TYPE),
        catalogType: String(toValue(params).catalogType || API_CATALOG_TYPE),
      };
    });

    return useTransport<
      CatalogBFFGoodsSearchV2Response,
      ITransformedProductsResponse
    >(urls.goods.products, {
      method: "POST",
      body,
      transform,
      key,
      immediate,
      watch: watch ? [body] : false,
    });
  };

  const requestProductById = (itemId: string) => {
    const url = `${urls.goods.product}${itemId}/stores/${storeShopCode.value}`;
    return useTransport<ICatalogBFFDetailedGood>(
      url,
      {
        lazy: import.meta.client,
        query: computed(() => ({
          storetype: String(API_SHOP_TYPE),
          catalogtype: API_CATALOG_TYPE,
        })),
        onResponseError(ctx) {
          useCreateError(ctx.response._data.message, ctx.response.status);
        },
      },
    );
  };

  const requestFilters = (params: IRequestGoodsFilters, key?: string) => {
    return useTransport<IGoodsFilters>(
      urls.goods.filters,
      {
        method: "POST",
        body: computed(() => (
          {
            ...params,
            catalogType: String(API_CATALOG_TYPE),
            storeType: String(API_SHOP_TYPE),
            includeAdultGoods: true,
          }
        )),
        key,
      },
    );
  };

  return {
    requestFilters,
    requestProducts,
    requestProductById,
  };
};
